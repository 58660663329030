<template>
  <section class="text-left message ms-Fabric dialogWindow" :key="viewIndex" v-bind:class="windowClass">
      
      <Message 
      v-if="conversation_message_uuid == ''"
      :message_uuid="message_uuid"
      @closeMessage="closeMessage"
      @showConversation="showConversation"
    ></Message>
    
    <Conversation v-if="conversation_message_uuid != ''"
    :message_uuid="conversation_message_uuid"
    :inbox="true"
    @gotoMessage="gotoMessage"
    @closeConversation="closeConversation"
    show-close-btn></Conversation>

  </section>
</template>
<script>
export default {
  components: {
    Conversation: () => import("@/components/Conversation/Conversation"),
    Message: () => import("@/components/View/Message"),
  },
  props: ["message_uuid"],
  data() {
    return {
      viewingIndex: 0,
      loa_error: 0,
      show: false,
      addinClient: "",
      opened: false,
      loading: true,
      participant_status: [],
      external_status: [],
      errorMessage: "",
      message: null,
      external: [],
      responded_message_id: "",
      isSecured: false,
      mainProps: { width: 20, height: 20 },
      conversation_message_uuid: "",
    };
  },
  methods: {
    showConversation(message_uuid){
      this.conversation_message_uuid = message_uuid;
    },
    closeConversation()
    {
      this.conversation_message_uuid = "";
    },
    closeMessage() {
      this.update_message_uuid = this.message_uuid;
      this.message_uuid = "";
    },
    gotoMessage(message_uuid){
      this.notification = null;
      this.conversation_message_uuid = '';
      this.message_uuid = message_uuid;
    },
    async init() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "OPENED",
        })
      );
      //await this.readMessage();
    },
    openBrowserWindow(url){
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "OPENBROWSERWINDOW",
          url: url
        })
      );
    },
    checkLastParticipant(index) {
      if (this.external.length != 0) {
        return false;
      } else {
        return (this.participantsNoCreator.length - 1 == index);
      }
    },
    async refreshBtn(){
      this.viewingIndex = this.viewingIndex + 1;
      //await this.readMessage();
    },
    setResponse(message_uuid) {
      this.responded_message_id = message_uuid;
    },
    removeMessage() {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h('p', { class: ['mb-0 pb-0'] }, [
          h('p', { class: ['text-center mb-0 pb-0'] }, this.$t('CONFIRM.DELETE_MESSAGE'))
        ]);
      if(this.isCreator)
      {
        messageVNode = h('p', { class: ['mb-0 pb-0'] }, [
          h('p', { class: ['text-center'] }, this.$t('CONFIRM.DELETE_MESSAGE')),
          h('p', { class: ['text-center mb-0 pb-0'] }, this.$t('CONFIRM.DELETE_MESSAGE_CREATOR'))
        ]);
      }
      if(!this.regular_message)
      {
        messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
          h(
            "p",
            { class: ["text-center mb-0 pb-0"] },
            this.$t("CONFIRM.DELETE_MEETING")
          ),
        ]);
        if (this.isCreator) {
          messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
            h("p", { class: ["text-center"] }, this.$t("CONFIRM.DELETE_MEETING")),
            h(
              "p",
              { class: ["text-center mb-0 pb-0"] },
              this.$t("CONFIRM.DELETE_MEETING_CREATOR")
            ),
          ]);
        }      
      }
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "//message/remove", {
                message_uuid: self.message_uuid,
                functionbox_uuid: ""
              })
              .then(() => {
                self.closeDialog();
              })
              .catch(function(error) {
                if(error.toString() == "Error: fail_4_4") return;
                self.$noty.error(self.$t("ERROR.COULD_NOT_SAVE"));
              });
          }
        })
        .catch(function() {});
    },
    async readMessage() {
      this.errorMessage = "";
      this.loa_error = 0;
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: this.message_uuid,
          }
        );
        this.message = result.data.message;
        this.participants = result.data.participants;
        this.external = result.data.external;
        this.opened = true;
        this.loading = false;
        return true;
      } catch (error) {
        this.loading = false;
        this.opened = false;
        if(error.toString() == "Error: fail_4_4") return false;
        if (error.response.data != undefined) {
          if (
            error.response.data == "ERROR.LOA_LOW3" ||
            error.response.data == "ERROR.LOA_LOW2"
          ) {
            this.loa_error = parseInt(error.response.data.slice(-1));
          }
          this.errorMessage = this.$t(error.response.data);
        }
        return false;
      }
    },
    replyMessage() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "REPLY",
          content: this.cleanMessage,
        })
      );
    },
    replyAllMessage() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "REPLYALL",
          content: this.cleanMessage,
        })
      );
    },
    closeDialog() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "CLOSE",
        })
      );
    },
  },
  computed: {
    direktLink() {
      return this.user.hostname.replace("/server/rest","") + "/message/" + this.message.message_uuid;
    },
    regular_message(){
      if(this.message.message_type == 'message' || this.message.message_type == "response" ||
        this.message.message_type == "link-response")
        {
          return true;
        }else{
          return false;
        }
    },
    responseType() {
      return (
        this.message.message_type == "response" ||
        this.message.message_type == "link-response"
      );
    },
    createdTime() {
      return this.$luxonDateTime.fromISO(this.message.created_date).toLocaleString(this.$luxonDateTime.DATETIME_MED);
    },
    createdName() {
      if (this.message.creator.name == "") {
        return this.message.creator.email;
      } else {
        return (
          this.message.creator.name +
          ", " +
          this.message.creator.organisation_name
        );
      }
    },
    isCreator() {
      return this.user.information.user_uuid == this.message.creator.user_uuid;
    },
    participantsNoCreator() {
      return this.participants.filter(
        (c) => c.email != this.user.information.email
      );
    },
    myResponse() {
      if (this.responded_message_id != "") {
        return this.responded_message_id;
      }
      let me = this.participants.filter(
        (c) => c.user_uuid == this.user.information.user_uuid
      );
      if (me.length != 0) {
        return me[0].response_message_uuid;
      }
      return "";
    },
    showResponseForm() {
      if (!this.isCreator && this.message.settings.require_response) {
        if (this.myResponse == "") {
          return true;
        }
      }
      return false;
    },
    showResponse() {
      if (!this.isCreator && this.message.settings.require_response) {
        if (this.myResponse != "") {
          return true;
        }
      }
      return false;
    },
    windowClass(){
      if(this.$Office.context.diagnostics.platform == undefined)
      {
        return "";
      }else{
        if(this.$Office.context.diagnostics.platform == "OfficeOnline")
        {
          return "pr-4";
        }else{
           return "pl-md-4 pr-md-4"; 
        }
      }
    },
    viewIndex() {
      return "view_" + this.viewingIndex;
    }
  },
  watch: {
    message_uuid: function(val, oldval) {
      if (val != oldval) {
        this.getInformation();
      }
    },
  },
  mounted() {
    console.log("ReadDialog mounted");
    this.init();
  },
};
</script>
<style></style>
